<template>
	<div class="search">
		<form
			class="form"
			@submit.prevent="submit">
			<label
				class="search__label"
				for="search">
				<NuxtIcon
					class="icon search__icon-search"
					name="search" />
			</label>

			<button
				v-if="searchValue.length"
				class="search__reset"
				@click="reset">
				<NuxtIcon
					class="icon search__icon-reset"
					name="dropdown-close" />
			</button>

			<input
				id="search"
				v-model="searchValue"
				type="text"
				class="search__field"
				:class="searchValue ? 'search__field-filled' : ''"
				:placeholder="placeholder"
				@input="submit" />
		</form>
	</div>
</template>

<script setup lang="ts">
	const { placeholder = "Поиск" } = defineProps<{
		placeholder?: string;
	}>();

	const { $debounce } = useNuxtApp();
	const searchValue = ref<string>("");

	const emits = defineEmits<{
		(event: "submit", value: string): void;
	}>();

	const submit = ($debounce as any)((): void => {
		emits("submit", searchValue.value.trim());
	}, 1000);

	const reset = () => {
		searchValue.value = "";

		emits("submit", searchValue.value);
	};
</script>

<style lang="scss">
	@import "search";
</style>
